@import "../../../mixins.scss";

.films-sorting-container {
  @include flex;
  @include justify-content(flex-end);  
  @include align-items(center);
  flex-wrap: wrap;

  & > span {
    margin-right: 12px;
  }

  .ant-select {
    margin-right: 12px;
    min-width: 150px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: 479px) {
    @include justify-content(flex-start);

    & > span {
      margin-bottom: 12px;
    }
    
    .ant-select {
      width: 100%;
      margin-right: 0;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}